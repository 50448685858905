import { Container, GridContainer, Section } from "@util/standard";

import React from "react";
import { TABLET_BREAKPOINT } from "@util/constants";
import styled from "styled-components";

interface Props {}

const KioskTemplate = () => {
  return (
    <SectionTop>
      <StyledFormWrapper flexDirection="column">
        <form>
          <GridContainer repeat={1} margin="0 0 15px" width="100%">
            <input name="mobile" type="number" placeholder="Mobile number" />
          </GridContainer>
          <GridContainer repeat={1} margin="0 0 15px" width="100%">
            <input name="email" type="email" placeholder="Email" />
          </GridContainer>
          <GridContainer repeat={1} margin="0 0 15px" width="100%">
            <input name="fullName" type="text" placeholder="Full name" />
          </GridContainer>
          <GridContainer repeat={1} margin="0 0 15px" width="100%">
            <input type="radio" id="huey" name="drone" value="huey" checked />
            <input type="radio" id="huey" name="drone" value="huey" checked />
          </GridContainer>
        </form>
      </StyledFormWrapper>
    </SectionTop>
  );
};

export default KioskTemplate;

const SectionTop = styled(Section)`
  padding: 150px 0;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 35px 0 10px;
  }
`;

const StyledFormWrapper = styled(Container)`
  form {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: auto;
  }
`;
